<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">定位器管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iwu" ></el-input>
                <el-input v-model="imei" placeholder="请输入IMEI" class="iwu" ></el-input>
                <el-select v-model="isOnline" placeholder="在线状态"  filterable  clearable class="iwu">
                        <el-option label="在线" value="1" ></el-option>
                        <el-option label="离线" value="0" ></el-option>
                </el-select>
                <el-select v-model="bindUnit" placeholder="是否绑定作业单位"  filterable  clearable class="iwu">
                        <el-option label="是" value="1" ></el-option>
                        <el-option label="否" value="0" ></el-option>
                </el-select>
                <el-select v-model="unitId" placeholder="选择作业单位"  filterable  clearable class="iwu" v-if="bindUnit=='1'" @change="changeUnitBySel">
                        <el-option v-for="item in unitList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="bindUser" placeholder="是否绑定作业人员"  filterable  clearable class="iwu" v-if="bindUnit=='1'">
                        <el-option label="是" value="1" ></el-option>
                        <el-option label="否" value="0" ></el-option>
                </el-select>
                <el-select v-model="userId" placeholder="选择已绑人员"  filterable  clearable class="iwu" v-if="bindUnit=='1'&&bindUser=='1'">
                        <el-option v-for="item in userListS" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="primary" @click="resetPost">重置</el-button>
                <el-button type="danger" @click="delMore" class="fr" >批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" >添加</el-button>


                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"  @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="IMEI" label="IMEI"></el-table-column>
                    <!-- <el-table-column prop="POSITIONERTYPENAME" label="类型"></el-table-column> -->
                    <el-table-column prop="ISLOGIN" label="在线状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISLOGIN==1" style="color:#67C23A">在线</span>
                            <span v-else style="color:#909399">离线</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="GPSTIME" label="最后一次GPS时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.GPSTIME!='1900-01-01 00:00:00'">{{scope.row.GPSTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WORKUNITName" label="作业单位">
                        <template slot-scope="scope">
                            <span v-if="scope.row.WORKUNITName">{{scope.row.WORKUNITName}}</span>
                            <span v-else>未绑定</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WORKUSERNAME" label="作业人员">
                        <template slot-scope="scope">
                            <span v-if="scope.row.WORKUSERNAME">{{scope.row.WORKUSERNAME}}</span>
                            <span v-else>未绑定</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SPEED" label="速度"></el-table-column>
                    <el-table-column prop="MIL" label="里程"></el-table-column>
                    <el-table-column prop="ELECTRIC" label="电量"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="IMEI" prop="imei">
                    <el-input v-model="postInfo.imei" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="作业单位">
                    <el-select  v-model="postInfo.workunitsnowid" placeholder="点击作业单位" filterable clearable style="width:100%" @change="changeUnitByForm">
                            <el-option v-for="item in unitList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="作业人员">
                    <el-select  v-model="postInfo.workusersnowid" placeholder="点击作业人员" filterable clearable style="width:100%">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var IMEI = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入IMEI"));
      } else {
        callback();
      }
    };

    return {
        canDo:true,

        name:"",
        imei:"",
        isOnline:"",
        bindUser:"",
        userId:"",
        bindUnit:"",
        unitId:"",


        unitList:[],
        userList:[],
        userListS:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            IMEI: [{ validator: IMEI, trigger: "blur" }],
        },

        allIds:[],
        chooseFileModal:false,
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
        this.workUnitSnowID=getQueryData.workUnitSnowID
        this.workUserSnowID=getQueryData.workUserSnowID
        this.isUnitBand=getQueryData.isUnitBand
        this.isBand=getQueryData.isBand
        this.isLogin=getQueryData.isLogin
        this.imei=getQueryData.imei
    }

    this.loadItem=null
    this.getPostList()
    this.getUnitList()
  },
  methods: {
    getUnitList(){
        this.$http.post("WorkUnit/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.unitList=response.data.DATA
                } 
            })
    },
    changeUnitByForm(e){
        this.postInfo.workusersnowid=""
        if(e!=""){
            this.getUserList(e)
        }
    },
    getUserList(id){
        this.$http.post("WorkUser/GetDataList", {parentSnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                } 
            })
    },
    changeUnitBySel(e){
        this.userId=""
        if(e!=""){
            this.getUserListS(e)
        }
    },
    getUserListS(id){
        //
    },
    getPostList(){
        this.showLoading("请稍后")
        var unitid=""
        var binduser=""
        var userid=""

        if(this.bindUnit=="1"){
            unitid=this.unitId
            if(this.bindUser=="1"){
                binduser=this.bindUser
                userid=this.userId
            }
        }
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            name:this.name,
            workUnitSnowID:unitid,
            workUserSnowID:userid,
            isUnitBand:this.bindUnit,
            isBand:binduser,
            isLogin:this.isOnline,
            imei:this.imei,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Positioner/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    resetPost(){
      this.name = ''
      this.imei = ''
      this.isOnline = ''
      this.bindUser = ''
      this.userId = ''
      this.bindUnit = ''
      this.unitId = ''
    },
    addPost(){
        this.postInfo={
            name:"",
            imei:"",
            workunitsnowid:"",
            workusersnowid:"",
        }
        this.doTypeStr="添加定位器"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Positioner/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
             });
    },
    editPost(item){
        var userid=""
        if(item.WORKUSERSNOWID && item.WORKUSERSNOWID!="0"){
            userid =item.WORKUSERSNOWID
        }
        var unitid=""
        if(item.WORKUNITSNOWID && item.WORKUNITSNOWID!="0"){
            unitid =item.WORKUNITSNOWID
        }
        this.postInfo={
            SnowID:item.SNOWID,
            name:item.NAME,
            imei:item.IMEI,
            workunitsnowid:unitid,
            workusersnowid:userid,
        }
        this.doTypeStr="修改定位器"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Positioner/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除定位器：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Positioner/DeleteData", {SnowID:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    //
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>