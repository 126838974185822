<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">作业单位管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入作业单位名称" class="iw" ></el-input>
                <el-select v-model="cqid" placeholder="选择采区"  filterable  clearable class="iw">
                        <el-option v-for="item in cqList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="DISPLAYNAME" label="单位名称"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column prop="PHONE" label="手机号"></el-table-column>
                    <el-table-column prop="CQMC" label="采区名称"></el-table-column>
                    <el-table-column prop="DWJB" label="单位级别"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="单位名称" prop="displayname">
                    <el-input v-model="postInfo.displayname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录名" prop="loginname">
                    <el-input v-model="postInfo.loginname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" v-if="doType=='1'">
                    <el-input v-model="postInfo.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="postInfo.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="采区">
                    <el-select v-model="postInfo.cqsnowid" placeholder="请选择系统用户"  filterable clearable style="width:100%;">
                        <el-option v-for="item in cqList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="单位级别" prop="dwjb">
                    <el-input v-model="postInfo.dwjb" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="位置">
                    <div style="overflow:hidden;margin-bottom:20px">
                        <el-input v-model="positionLon" autocomplete="off" placeholder="经度" style="width:44%;"></el-input>
                        <el-input v-model="positionLat" autocomplete="off" placeholder="纬度" style="width:44%;" class="ml20"></el-input>
                        <el-button type="primary" @click="setPositionLonLat()" class="fr ml20">确定</el-button>
                    </div>
                    <TianMap class="map" ref="tmap" :width="mapWidth" :height="mapHeight" style="float:left" :move="false" :draw="true" @mapFinish="tianmapFinishA"></TianMap>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import TianMap from "../../components/OpenLayers"
export default {
  name: "UnitManage",
  components:{
    TianMap,
  },
  data() {
    var displayname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入单位名称"));
      } else {
        callback();
      }
    };
    var loginname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入登录名"));
      } else {
        callback();
      }
    };
    var password = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var phone = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    // var cqsnowid = (rule, value, callback) => {
    //   if (value === "" || value == null) {
    //     callback(new Error("请选择所在采区"));
    //   } else {
    //     callback();
    //   }
    // };
    var dwjb=(rule, value, callback) => {
        if(value !== '' && typeof value != 'number' && isNaN(value)){
             callback(new Error('单位级别只能是数字'));
        }else if(value !== '' && !/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    
    return {
        
        canDo:true,
        showPagination:false,
        name:"",
        cqid:"",

        cqList:[],

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            displayname: [{ validator: displayname, trigger: "blur" }],
            loginname: [{ validator: loginname, trigger: "blur" }],
            phone: [{ validator: phone, trigger: "blur" }],
            password: [{ validator: password, trigger: "blur" }],
            // cqsnowid: [{ validator: cqsnowid, trigger: "blur" }],
            dwjb: [{ validator: dwjb, trigger: "blur" }],
        },


        mapWidth:1060,
        mapHeight:600,
        positionLon:0,
        positionLat:0,
        tianmap:null,
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
        this.cqid=getQueryData.cqSnowID
    }
    this.loadItem=null
    this.getCQList()
    this.getPostList()
  },
  methods: {
    getCQList(){
        this.$http.post("CQ/GetDataList", {})
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.cqList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.name,
            cqSnowID:this.cqid,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("WorkUnit/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

    addPost(){
        this.postInfo={
            displayname:"",
            loginname:"",
            phone:"",
            password:"",
            cqsnowid:"",
            dwjb:"",
            lon:0,
            lat:0,
            x:0,
            y:0,
        }
        this.doTypeStr="添加作业单位"
        this.doType="1"
        this.postModal=true
        setTimeout(()=>{
            if(this.tianmap){
                this.$refs.tmap.clearDraw()
            }
            var t=this.$refs.tmap.getMapTarget()
            var c= t.view.getCenter()
            this.postInfo.lon=c[0]
            this.postInfo.lat=c[1]
            this.positionLon=c[0]
            this.positionLat=c[1]
            this.initMarker(c)
        },500)
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("WorkUnit/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    editPost(item){
        this.postInfo={
            snowid:item.SNOWID,
            displayname:item.DISPLAYNAME,
            loginname:item.LOGINNAME,
            phone:item.PHONE,
            password:item.PASSWORD,
            cqsnowid:item.CQSNOWID,
            dwjb:item.DWJB,
            lon:item.LON,
            lat:item.LAT,
            x:item.X,
            y:item.Y,
        }
        this.doTypeStr="修改作业单位"
        this.doType="2"
        this.postModal=true
        setTimeout(()=>{
            if(this.tianmap){
                this.$refs.tmap.clearDraw()
            }
            var  t=this.$refs.tmap.getMapTarget()
            var c= null
            if(item.LON !=0 && item.LAT !=0){
                c=[item.LON,item.LAT]
            }else{
                c=t.view.getCenter()
            }
            // console.log(c)
            // c=t.view.getCenter()
            this.postInfo.lon=c[0]
            this.postInfo.lat=c[1]
            this.positionLon=c[0]
            this.positionLat=c[1]
            this.initMarker(c)
        },500)
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("WorkUnit/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    tianmapFinishA(){
        this.tianmap=this.$refs.tmap.map
    },
    initMarker(point){
        this.$refs.tmap.appendDrawPoint(point,true,this.sendChangePoint)
    },
    sendChangePoint(point){
        this.postInfo.lon=point[0]
        this.postInfo.lat=point[1]
        this.positionLon=point[0]
        this.positionLat=point[1]
    },
    setPositionLonLat(){
        var lon = /^-?((0|[1-9]\d?|1[1-7]\d)(\.\d{1,20})?|180(\.0{1,20})?)?$/;
        var lat = /^-?((0|[1-8]\d|)(\.\d{1,20})?|90(\.0{1,20})?)?$/; 
        var lonRe = new RegExp(lon);
        var latRe = new RegExp(lat);
        var llon=parseFloat(this.positionLon)
        var llat=parseFloat(this.positionLat)
        if(lonRe.test(llon) && latRe.test(llat)){
            this.postInfo.lon=llon
            this.postInfo.lat=llat
            var  t=this.$refs.tmap.getMapTarget()
            var c=[llon,llat]
            t.view.setCenter(c)
            this.$refs.tmap.clearDraw()
            this.initMarker(c)
        }else{
            this.$message.error("经纬度格式不正确")
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除作业单位：'+item.DISPLAYNAME+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("WorkUnit/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>