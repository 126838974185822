<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">井口管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入井口名称" class="iw" ></el-input>
                <el-input v-model="code" placeholder="请输入井口编号" class="iw" ></el-input>
                <el-select v-model="cqid" placeholder="选择采区"  filterable  clearable class="iw">
                        <el-option v-for="item in cqList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="bindLand" placeholder="是否绑定地块"  filterable  clearable class="iw">
                        <el-option label="是" value="1" ></el-option>
                        <el-option label="否" value="0" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="warning" @click="exportPost" class="fr">导入</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                <el-button type="primary" @click="createTask" class="fr">生成任务</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="井口名称"></el-table-column>
                    <el-table-column prop="CODE" label="井口代码"></el-table-column>
                    <el-table-column prop="CQMC" label="采区名称"></el-table-column>
                    <el-table-column prop="CQDM" label="采区代码"></el-table-column>
                    <el-table-column prop="RJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="LandName" label="所属地块">
                        <template slot-scope="scope">
                            <span v-if="scope.row.LandName && scope.row.LandName!=''">{{scope.row.LandName}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SZXZQDM" label="行政区代码" width="90"></el-table-column>
                    <el-table-column prop="SZXZQMC" label="行政区名称" width="90"></el-table-column>
                    <el-table-column prop="GZZT" label="工作状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.GZZT== '1'">开采</span>
                            <span v-else-if="scope.row.GZZT== '2'">长停</span>
                            <span v-else-if="scope.row.GZZT== '3'">废弃</span>
                            <span v-else-if="scope.row.GZZT== '4'">在建</span>
                            <span v-else-if="scope.row.GZZT== '5'">观察</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SBZT" label="设备状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.GZZT== '1'">新建</span>
                            <span v-else-if="scope.row.GZZT== '2'">正常</span>
                            <span v-else-if="scope.row.GZZT== '3'">已封</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SBXH" label="设备型号"></el-table-column>
                    <el-table-column prop="BCZT" label="保存状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.GZZT== '1'">被违建</span>
                            <span v-else-if="scope.row.GZZT== '2'">被损毁</span>
                            <span v-else-if="scope.row.GZZT== '3'">消失</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DLMC" label="地类名称"></el-table-column>
                    <el-table-column prop="DLBM" label="地类编码"></el-table-column>
                    <el-table-column prop="JKYD" label="井口用地"></el-table-column>
                    <el-table-column prop="JKLY" label="井口利用"></el-table-column>
                    <el-table-column prop="XDM" label="小地名"></el-table-column>
                    <el-table-column prop="CHFS" label="测绘方式">
                        <template slot-scope="scope">
                            <span v-if="scope.row.GZZT== '1'">实测</span>
                            <span v-else-if="scope.row.GZZT== '2'">放样</span>
                            <span v-else-if="scope.row.GZZT== '3'">图解</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="JXSM" label="界限说明">
                        <template slot-scope="scope">
                            <span v-if="scope.row.GZZT== '1'">有界限</span>
                            <span v-else-if="scope.row.GZZT== '2'">无界限</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="KTZDL" label="可调整地类" width="90"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" top="20px"  :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="displayname">
                    <el-input v-model="postInfo.displayname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="代码" prop="code">
                    <el-input v-model="postInfo.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="采区">
                    <el-select v-model="postInfo.cqsnowid" placeholder="请选择采区"  filterable clearable style="width:100%;">
                        <el-option v-for="item in cqList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw">
                    <el-input v-model="postInfo.ejdw" autocomplete="off" placeholder="请输入二级单位"></el-input>
                </el-form-item>
                <el-form-item label="所属地块">
                    <el-select v-model="postInfo.landsnowid" placeholder="请选择地块"  filterable clearable style="width:100%;">
                        <el-option v-for="item in landList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行政区代码">
                    <el-input v-model="postInfo.szxzqdm" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="行政区名称">
                    <el-input v-model="postInfo.szxzqmc" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="工作状态">
                    <el-select v-model="postInfo.gzzt" placeholder="请选择工作状态"  filterable  style="width:100%;">
                        <el-option value="1" label="开采"></el-option>
                        <el-option value="2" label="长停"></el-option>
                        <el-option value="3" label="废弃"></el-option>
                        <el-option value="4" label="在建"></el-option>
                        <el-option value="5" label="观察"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备状态">
                    <el-select v-model="postInfo.sbzt" placeholder="请选择设备状态"  filterable  style="width:100%;">
                        <el-option value="1" label="新建"></el-option>
                        <el-option value="2" label="正常"></el-option>
                        <el-option value="3" label="已封"></el-option>
                        <el-option value="4" label="无"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备型号">
                    <el-input v-model="postInfo.sbxh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="保存状态">
                    <el-select v-model="postInfo.bczt" placeholder="请选择保存状态"  filterable  style="width:100%;">
                        <el-option value="1" label="被违建"></el-option>
                        <el-option value="2" label="被损毁"></el-option>
                        <el-option value="3" label="消失"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地类名称">
                    <el-input v-model="postInfo.dlmc" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地类编码">
                    <el-input v-model="postInfo.dlbm" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="井口用地">
                    <el-input v-model="postInfo.jkyd" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="井口利用">
                    <el-input v-model="postInfo.jkly" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="小地名">
                    <el-input v-model="postInfo.xdm" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="测绘方式">
                    <el-select v-model="postInfo.chfs" placeholder="请选择测绘方式"  filterable  style="width:100%;">
                        <el-option value="1" label="实测"></el-option>
                        <el-option value="2" label="放样"></el-option>
                        <el-option value="3" label="图解"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="界限说明">
                    <el-select v-model="postInfo.jxsm" placeholder="请选择界限说明"  filterable  style="width:100%;">
                        <el-option value="1" label="有界限"></el-option>
                        <el-option value="2" label="无界限"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="可调整地类">
                    <el-select v-model="postInfo.ktzdl" placeholder="请选择可调整地类"  filterable  style="width:100%;">
                        <el-option value="1" label="水田"></el-option>
                        <el-option value="2" label="旱地"></el-option>
                        <el-option value="3" label="建设用地"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="postInfo.bz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="位置">
                    <div style="overflow:hidden;margin-bottom:20px">
                        <el-input v-model="positionLon" autocomplete="off" placeholder="经度" style="width:44%;"></el-input>
                        <el-input v-model="positionLat" autocomplete="off" placeholder="纬度" style="width:44%;" class="ml20"></el-input>
                        <el-button type="primary" @click="setPositionLonLat()" class="fr ml20">确定</el-button>
                    </div>
                    <TianMap class="map" ref="tmap" :width="mapWidth" :height="mapHeight" style="float:left" :move="false" :draw="true" @mapFinish="tianmapFinishA"></TianMap>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="uploadWell"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>
import TianMap from "../../components/OpenLayers"
export default {
  name: "UnitManage",
  components:{
    TianMap,
  },
  data() {
    var displayname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入井口名称"));
      } else {
        callback();
      }
    };
    var code = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入代码"));
      } else {
        callback();
      }
    };
    
    return {
        
        canDo:true,
        showPagination:false,
        name:"",
        cqid:"",
        code:"",
        bindLand:"",

        landList:[],
        cqList:[],

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            displayname: [{ validator: displayname, trigger: "blur" }],
            code: [{ validator: code, trigger: "blur" }],
        },


        mapWidth:1060,
        mapHeight:600,
        positionLon:0,
        positionLat:0,
        tianmap:null,

        exportTable:[],
        exportTableModal:false,
        chooseFileModal:false,

        selectIds:[],
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
        this.cqid=getQueryData.CQSNOWID
        this.code=getQueryData.Code
        this.bindLand=getQueryData.IsLandBand
    }
    this.loadItem=null
    this.getLandList()
    this.getCQList()
    this.getPostList()
  },
  methods: {
    handleSelectionChange(val) {
        this.selectIds=[]
        for(var i=0;i<val.length;i++){
            this.selectIds.push(val[i].SNOWID)
        }
    },
    createTask(){
        if(this.canDo){
            this.sendCreateTask()
        }
    },
    sendCreateTask(){
        this.showLoading("请稍后")
        this.canDo=false
        this.$http.post("Task/OneCreateWellTask", this.selectIds)
            .then((response) => {
                this.hideLoading()
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.$confirm('是否跳转到任务管理中查看？', '生成结束', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$router.replace("/OilWellTaskManage");
                    }).catch(() => {
                        //
                    });
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.canDo=true
                this.$message.error("请求失败，请稍后再试")
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });   
    },
    getCQList(){
        this.$http.post("CQ/GetDataList", {})
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.cqList=response.data.DATA
                }
            })
    },
    getLandList(){
        this.$http.post("Land/GetDataList", {})
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.landList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.name,
            CQSNOWID:this.cqid,
            Code:this.code,
            IsLandBand:this.bindLand,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Well/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

    addPost(){
        this.postInfo={
            displayname:"",
            code:"",
            landsnowid:"",
            dcrsnowid:"",
            bz:"",
            ejdw:"",
            cqsnowid:"",
            lon:0,
            lat:0,
            x:0,
            y:0,
            szxzqdm:"",
            szxzqmc:"",
            gzzt:"",
            sbzt:"",
            sbxh:"",
            bczt:"",
            dlbm:"",
            dlmc:"",
            jkyd:"",
            jkly:"",
            xdm:"",
            chfs:"",
            jxsm:"",
            ktzdl:"",
        }
        this.doTypeStr="添加井口"
        this.doType="1"
        this.postModal=true
        setTimeout(()=>{
            if(this.tianmap){
                this.$refs.tmap.clearDraw()
            }
            var t=this.$refs.tmap.getMapTarget()
            var c= t.view.getCenter()
            this.postInfo.lon=c[0]
            this.postInfo.lat=c[1]
            this.positionLon=c[0]
            this.positionLat=c[1]
            this.initMarker(c)
        },500)
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Well/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    editPost(item){
        this.postInfo={
            SnowID:item.SNOWID,
            displayname:item.DISPLAYNAME,
            code:item.CODE,
            ejdw:item.EJDW,
            landsnowid:item.LANDSNOWID,
            dcrsnowid:item.DCRSNOWID,
            bz:item.BZ,
            cqsnowid:item.CQSNOWID,
            lon:item.LON,
            lat:item.LAT,
            x:item.X,
            y:item.Y,
            szxzqdm:item.SZXZQDM,
            szxzqmc:item.SZXZQMC,
            gzzt:item.GZZT,
            sbzt:item.SBZT,
            sbxh:item.SBXH,
            bczt:item.BCZT,
            dlbm:item.DLBM,
            dlmc:item.DLMC,
            jkyd:item.JKYD,
            jkly:item.JKLY,
            xdm:item.XDM,
            chfs:item.CHFS,
            jxsm:item.JXSM,
            ktzdl:item.KTZDL,
        }
        this.doTypeStr="修改井口"
        this.doType="2"
        this.postModal=true
        setTimeout(()=>{
            if(this.tianmap){
                this.$refs.tmap.clearDraw()
            }
            var  t=this.$refs.tmap.getMapTarget()
            var c= null
            if(item.LON !=0 && item.LAT !=0){
                c=[item.LON,item.LAT]
            }else{
                c=t.view.getCenter()
            }
            this.postInfo.lon=c[0]
            this.postInfo.lat=c[1]
            this.positionLon=c[0]
            this.positionLat=c[1]
            this.initMarker(c)
        },500)
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Well/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    tianmapFinishA(){
        this.tianmap=this.$refs.tmap.map
    },
    initMarker(point){
        this.$refs.tmap.appendDrawPoint(point,true,this.sendChangePoint)
    },
    sendChangePoint(point){
        this.postInfo.lon=point[0]
        this.postInfo.lat=point[1]
        this.positionLon=point[0]
        this.positionLat=point[1]
    },
    setPositionLonLat(){
        var lon = /^-?((0|[1-9]\d?|1[1-7]\d)(\.\d{1,20})?|180(\.0{1,20})?)?$/;
        var lat = /^-?((0|[1-8]\d|)(\.\d{1,20})?|90(\.0{1,20})?)?$/; 
        var lonRe = new RegExp(lon);
        var latRe = new RegExp(lat);
        var llon=parseFloat(this.positionLon)
        var llat=parseFloat(this.positionLat)
        if(lonRe.test(llon) && latRe.test(llat)){
            this.postInfo.lon=llon
            this.postInfo.lat=llat
            var  t=this.$refs.tmap.getMapTarget()
            var c=[llon,llat]
            t.view.setCenter(c)
            this.$refs.tmap.clearDraw()
            this.initMarker(c)
        }else{
            this.$message.error("经纬度格式不正确")
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除井口：'+item.DISPLAYNAME+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Well/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    exportPost(){
        this.chooseFileModal=true 
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      this.$upload.post("Well/UpLoadWells", fd)
        .then((response) => {
         this.hideLoading()
            if(response.data.SUCCESS) {
                this.hideLoading()
                this.chooseFileModal=false
                this.PageIndex=1
                this.getPostList()
                this.$message.success("导入完成")
            }else{
                this.hideLoading()
                this.$message.error("上传失败")
            }
        })
        .catch((error) => {
            this.hideLoading()
            if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>