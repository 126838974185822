<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">成果汇交查看</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入地块名称" class="iw" ></el-input>
                <el-select v-model="ejdw" placeholder="选择二级单位"  filterable  clearable class="iw">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-select v-model="fzdw" placeholder="选择发证单位"  filterable  clearable class="iw">
                        <el-option v-for="item in fzdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="LandName" label="地块名称"></el-table-column>
                    <el-table-column prop="LandStatus" label="地块现状">
                        <template slot-scope="scope">
                            <span v-if="scope.row.LandStatus== '1'">正常</span>
                            <span v-else-if="scope.row.LandStatus== '2'">侵占</span>
                            <span v-else-if="scope.row.LandStatus== '3'">耕地</span>
                            <span v-else-if="scope.row.LandStatus== '4'">无井</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="ZSBH" label="证书编号"></el-table-column>
                    <el-table-column prop="QLR" label="权利人"></el-table-column>
                    <el-table-column prop="GYQK" label="共有情况"></el-table-column>
                    <el-table-column prop="ZL" label="坐落"></el-table-column>
                    <el-table-column prop="BDCDYH" label="不动产单元号"></el-table-column>
                    <el-table-column prop="QLLX" label="权利类型"></el-table-column>
                    <el-table-column prop="QLXZ" label="权利性质"></el-table-column>
                    <el-table-column prop="YT" label="用途"></el-table-column>
                    <el-table-column prop="MJ" label="面积（㎡）"></el-table-column>
                    <el-table-column prop="SYQX" label="使用期限"></el-table-column>
                    <el-table-column prop="QLQTZK" label="权利其他状况"></el-table-column>
                    <el-table-column prop="FZDW" label="发证地区"></el-table-column>
                    <el-table-column prop="CAD" label="CAD源文件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CAD !=''" @click="downloadFile(scope.row.CAD)" style="color:#409EFF;cursor:pointer;">点击下载</span>
                            <span v-else>未上传</span>    
                        </template>
                    </el-table-column>
                    <el-table-column prop="PDF" label="证书扫描件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.PDF !=''" @click="downloadFile(scope.row.PDF)" style="color:#409EFF;cursor:pointer;">点击查看</span>
                            <span v-else>未上传</span>    
                        </template>
                    </el-table-column>
                    <el-table-column prop="SubmitUserName" label="提交人"></el-table-column>
                    
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delFruit(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="fruitModal" width="800px" :close-on-click-modal="false" top="20px">
            <el-form :model="fruitInfo" status-icon :rules="fruitrules"  ref="fForm" label-width="200px" class="demo-uForm">
                <el-form-item label="地块名称">
                    <p>{{friuitLandName}}</p>
                </el-form-item>
                <el-form-item label="地块现状" prop="landStatus">
                    <el-select v-model="fruitInfo.landStatus" placeholder="请选择地块现状"  filterable  style="width:100%;">
                        <el-option value="1" label="正常"></el-option>
                        <el-option value="2" label="侵占"></el-option>
                        <el-option value="3" label="耕地"></el-option>
                        <el-option value="4" label="无井"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw">
                    <el-select v-model="fruitInfo.ejdw" placeholder="请选择二级单位"  filterable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="不动产证书编号" prop="zsbh">
                    <el-input v-model="fruitInfo.zsbh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利人" prop="qlr">
                    <el-input v-model="fruitInfo.qlr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="共有情况" prop="gyqk">
                    <el-input v-model="fruitInfo.gyqk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="坐落" prop="zl">
                    <el-input v-model="fruitInfo.zl" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号" prop="bdcdyh">
                    <el-input v-model="fruitInfo.bdcdyh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利类型" prop="qllx">
                    <el-input v-model="fruitInfo.qllx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利性质" prop="qlxz">
                    <el-input v-model="fruitInfo.qlxz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用途" prop="yt">
                    <el-input v-model="fruitInfo.yt" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="mj">
                    <el-input v-model="fruitInfo.mj" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="使用期限" prop="syqx">
                    <el-input v-model="fruitInfo.syqx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利其他状况">
                    <el-input v-model="fruitInfo.qlqtzk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发证地区" prop="fzdw">
                    <el-select v-model="fruitInfo.fzdw" placeholder="请选择发证地区"  filterable allow-create style="width:100%;">
                        <el-option v-for="item in fzdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证书扫描件上传" prop="smj">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :http-request="uploadFunC"
                        :before-upload="beforeAvatarUploadC">
                        <span v-if="smjFileName!= ''" style="color:#409EFF;">{{smjFileName}}</span>
                            <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="smjFileName!= ''" type="danger" size="mini" @click="deleteFileC()">取消选择</el-button>
                </el-form-item>
                <el-form-item label="CAD源文件上传">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :http-request="uploadFunD"
                        :before-upload="beforeAvatarUploadD">
                        <span v-if="cadFileName!=''" style="color:#409EFF;">{{cadFileName}}</span>
                            <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="cadFileName!=''" type="danger" size="mini" @click="deleteFileD()">取消选择</el-button>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitFruitForm()">提交</el-button>
                    <el-button @click="fruitModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "RegionManage",
  data() {
    var landStatus = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择地块现状"));
      } else {
        callback();
      }
    };

    var ejdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择二级单位"));
      } else {
        callback();
      }
    };

    var qlr = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利人"));
      } else {
        callback();
      }
    };

    var gyqk = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入共有情况"));
      } else {
        callback();
      }
    };

    var zl = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入坐落"));
      } else {
        callback();
      }
    };

    var bdcdyh = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入不动产单元号"));
      } else {
        callback();
      }
    };

    var qllx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利类型"));
      } else {
        callback();
      }
    };

    var qlxz = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利性质"));
      } else {
        callback();
      }
    };

    var yt = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入用途"));
      } else {
        callback();
      }
    };
    
    var mJ_M=(rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };
    var syqx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用期限"));
      } else {
        callback();
      }
    };

    var fzdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入发证单位"));
      } else {
        callback();
      }
    };


    var smj=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传证书扫描件"));
      } else {
        callback();
      }
    };

    var zsbh=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入土地证（不动产证）号"));
      } else {
        callback();
      }
    };

    return {
        canDo:true,
        showPagination:false,
        ejdw:"",
        fzdw:"",
        name:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        ejdwList:[],
        fzdwList:[],
        fruitModal:false,
        fruitInfo:{},
        fruitrules: {
            landStatus: [{ validator: landStatus, trigger: "blur" }],
            ejdw: [{ validator: ejdw, trigger: "blur" }],
            zsbh: [{ validator: zsbh, trigger: "blur" }],
            qlr: [{ validator: qlr, trigger: "blur" }],
            gyqk: [{ validator: gyqk, trigger: "blur" }],
            zl: [{ validator: zl, trigger: "blur" }],
            bdcdyh: [{ validator: bdcdyh, trigger: "blur" }],
            qllx: [{ validator: qllx, trigger: "blur" }],
            qlxz: [{ validator: qlxz, trigger: "blur" }],
            yt: [{ validator: yt, trigger: "blur" }],
            mj: [{ validator: mJ_M, trigger: "blur" }],
            syqx: [{ validator: syqx, trigger: "blur" }],
            fzdw: [{ validator: fzdw, trigger: "blur" }],
            smj:[{ validator: smj, trigger: "blur" }],
        },
        friuitLandName:"",

        smjFile:null,
        smjFileSrc:"",
        smjFileId:"",
        smjFileName:"",
        chooseSmjFile:false,
        cadFile:null,
        cadFileSrc:"",
        cadFileId:"",
        cadFileName:"",
        chooseCadFile:false,
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.landName
        this.ejdw=getQueryData.ejdw
        this.fzdw=getQueryData.fzdw
    }
    this.loadItem=null
    this.getPostList()
    this.getEjdwList()
    this.getFzdwList()
  },
  methods: {
    downloadFile(src){
        window.open(src)
    },
    getFzdwList(){
        this.$http.post("ExchangeResult/GetDistrictList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fzdwList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            ejdw:this.ejdw,
            fzdw:this.fzdw,
            landName:this.name,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("ExchangeResult/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var nl=[]
                    for(var i=0;i<response.data.DATA.length;i++){
                        var d=response.data.DATA[i]
                        d.CAD=""
                        d.PDF=""
                        for(var j=0;j<d.Files.length;j++){
                            if(d.Files[j].ImageType==2){
                                d.PDF=d.Files[j].Path
                            }else if(d.Files[j].ImageType==3){
                                d.CAD=d.Files[j].Path
                            }
                        }
                        nl.push(d)
                    }
                    this.postList=nl
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    editPost(item){
        this.smjFile=null
        this.smjFileSrc=""
        this.smjFileId=""
        this.smjFileName=""
        this.chooseSmjFile=false

        this.cadFile=null
        this.cadFileSrc=""
        this.cadFileId=""
        this.cadFileName=""
        this.chooseCadFile=false

        this.friuitLandName=item.DISPLAYNAME
        this.doTypeStr=item.DISPLAYNAME+"成果汇交登记"

        var  smj=""
        for(var i=0;i<item.Files.length;i++){
            if(item.Files[i].ImageType ==2){
                smj="1"
                this.smjFileName="证书扫描件.pdf"
                this.smjFileSrc=item.Files[i].Path
                this.smjFileId=item.Files[i].SnowID
            }else if(item.Files[i].ImageType ==3){
                this.cadFileName="CAD源文件.dwg"
                this.cadFileSrc=item.Files[i].Path
                this.cadFileId=item.Files[i].SnowID
            }
        }
        this.fruitInfo={
            SnowID:item.SnowID,
            landSnowID:item.LandSnowID,
            landStatus:item.LandStatus,
            submitUserSnowID:this.$store.state.snowid,
            ejdw:item.EJDW,
            zsbh:item.ZSBH,
            qlr:item.QLR,
            gyqk:item.GYQK,
            zl:item.ZL,
            bdcdyh:item.BDCDYH,
            qllx:item.QLLX,
            qlxz:item.QLXZ,
            yt:item.YT,
            mj:item.MJ,
            syqx:item.SYQX,
            qlqtzk:item.QLQTZK,
            fzdw:item.FZDW,
            smj:smj
        }
        this.doType="2"
        this.fruitModal=true
    },
    submitFruitForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    delete  this.fruitInfo.smj
                    this.sendFEdit()
                } else {
                    return false;
                }
            });
        }
    },
    sendFEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/UpdateData", this.fruitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已提交")
                    this.fruitModal=false
                    this.getPostList()
                    if(this.chooseSmjFile){
                        this.addImgC(this.fruitInfo.SnowID)
                    }
                    if(this.chooseCadFile){
                        this.addImgD(this.fruitInfo.SnowID)
                    }
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    delFruit(item){
        if(this.canDo){
            this.$confirm('确定删除地块'+item.LandName+'的成果汇交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDelFruit(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDelFruit(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.fruitModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    beforeAvatarUploadC(file) {
        const isJPG = file.type === 'application/pdf';
        if (!isJPG) {
        this.$message.error('证书扫描件只能是 PDF  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.smjFileSrc = _URL.createObjectURL(file);
            this.chooseSmjFile=true
            this.smjFile = file
            this.smjFileName=file.name
            this.fruitInfo.smj="1"
        }
        return isJPG;
    },
    uploadFunC(file){
        //console.log(file)
    },
    beforeAvatarUploadD(file) {
        var  isJPG =false;
        if (file.name.indexOf(".")== -1) {
            this.$message.error('CAD源文件件只能是 DWG  格式!');
        }else{
            if(file.name.split(".")[1] !="dwg"){
                this.$message.error('CAD源文件件只能是 DWG  格式!');
            }else{
                isJPG=true
                var _URL = window.URL || window.webkitURL;
                this.cadFileSrc = _URL.createObjectURL(file);
                this.chooseCadFile=true
                this.cadFile = file
                this.cadFileName=file.name
            }
        }
        return isJPG;
    },
    uploadFunD(file){
        //console.log(file)
    },
    deleteFileC(){
        this.smjFile =null
        this.smjFileSrc=""
        this.smjFileName=""
        this.chooseSmjFile=false
        this.fruitInfo.smj=""

        if( this.smjFileId!=""){
            this.sendDelFile(this.smjFileId)
        }
    },
    deleteFileD(){
        this.cadFile =null
        this.cadFileSrc=""
        this.cadFileName=""
        this.chooseCadFile=false
        if( this.cadFileId!=""){
            this.sendDelFile(this.cadFileId)
        }
    },
    sendDelFile(id){
        this.$http.post("File/DeleteFile", {SnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },

    addImgC(id){
        var fd = new FormData();
        fd.append("file", this.smjFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=2&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
    addImgD(id){
        var fd = new FormData();
        fd.append("file", this.cadFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=3&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>