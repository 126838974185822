<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">首页总览</p>
        </div>
        <div class="tableBox" style="top:50px;">
            
        </div>
    </div>
</template>


<script>
import * as echarts from 'echarts';
require("echarts/theme/macarons")
export default {
  name: "DataView",
  data() {
    return {
        canDo:true,
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {


  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
</style>