<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">作业人员查看</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入姓名" class="iw" ></el-input>
                <el-select v-model="cqid" placeholder="选择采区"  filterable  clearable class="iw">
                        <el-option v-for="item in cqList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="dwid" placeholder="选择作业单位"  filterable  clearable class="iw">
                        <el-option v-for="item in dwList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="shzt" placeholder="审核状态"  filterable  clearable class="iw">
                        <el-option label="待审核" value="0" ></el-option>
                        <el-option label="通过" value="1" ></el-option>
                        <el-option label="不通过" value="2" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="DISPLAYNAME" label="姓名"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column prop="PHONE" label="手机号"></el-table-column>
                    <el-table-column prop="CQMC" label="采区名称"></el-table-column>
                    <el-table-column prop="ParentName" label="作业单位"></el-table-column>
                    <el-table-column prop="ISDEACTIVATE" label="审核状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISDEACTIVATE== 0" style="color:#ff9900">待审核</span>
                            <span v-else-if="scope.row.ISDEACTIVATE== 1" style="color:#19be6b">通过</span>
                            <span v-else-if="scope.row.ISDEACTIVATE== 2" style="color:#ed4014">不通过</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TaskCount" label="任务数量"></el-table-column>
                    <el-table-column prop="SubmitRightTaskCount" label="正常提交数量"></el-table-column>
                    <el-table-column prop="SubmitWrongTaskCount" label="异常标注数量"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <!-- <el-button @click="trackPost(scope.row)" type="text" size="small" style="color:#E6A23C">轨迹查看</el-button> -->
                            <el-button @click="auditing(scope.row)" type="text" size="small" style="color:#ff9900" v-if="scope.row.ISDEACTIVATE==0">审核</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="轨迹查询" :visible.sync="tianmapModal" width="1100px" top="30px" :close-on-click-modal="false">
            <TianMap class="map" ref="tianmap" :width="mapWidth" :height="mapHeight" @mapFinish="tianmapFinish"  @updateTrackButtons="updateTrackButtons" :move="false" :draw="true"></TianMap>
            <div style="margin: 20px 0;">
                <el-button @click="tianmapModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="queryTrack()" class="fr ml20">查询</el-button>
            </div>
            <div style="height:40px;"></div>
        </el-dialog>
        <el-dialog :title="auditingTitle" :visible.sync="auditingModal" width="800"  :close-on-click-modal="false">
            <div style="overflow:hidden;">
                <el-radio v-model="auditingRadio" label="1">通过</el-radio>
                <el-radio v-model="auditingRadio" label="2">不通过</el-radio>
            </div> 
            <div style="margin: 20px 0;">
                <el-button @click="auditingModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="sendAuditing()" class="fr ml20">提交</el-button>
            </div>
            <div style="height:20px"></div>
        </el-dialog>

    </div>
</template>
<script>
import TianMap from "../../components/OpenLayers"
export default {
  name: "WorkUserList",
  components:{
    TianMap,
  },
  data() {
    return {
        canDo:true,
        showPagination:false,

        cqid:"",
        name:"",
        dwid:"",
        shzt:"",

        cqList:[],
        dwList:[],

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,

        tianmapModal:false,
        tianmap:null,
        mapWidth:1060,
        mapHeight:600,

        auditingModal:false,
        auditingTitle:"",
        auditingUserInfo:null,
        auditingRadio:"1",
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
        this.cqid=getQueryData.cqSnowID
        this.dwid=getQueryData.parentSnowID
        this.shzt=getQueryData.Status
    }
    this.loadItem=null
    this.getCQList()
    this.getDWList()
    this.getPostList()
  },
  methods: {
    getCQList(){
        this.$http.post("CQ/GetDataList", {})
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.cqList=response.data.DATA
                }
            })
    },
    getDWList(){
        this.$http.post("WorkUnit/GetDataList", {})
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.dwList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            cqSnowID:this.cqid,
            name:this.name,
            parentSnowID:this.dwid,
            Status:this.shzt,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("WorkUser/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },
    tianmapFinish(){
        this.tianmap=this.$refs.tianmap.map
    },
    trackPost(item){
        this.tianmapModal=true
        if(this.tianmap){
            this.$refs.tianmap.clearDraw()
        }
    },
    queryTrack(){
        var params={
            "USERID": "1801053018242813952",
            "STARTDATE": "2024-06-28 00:00:00",
            "ENDDATE": "2024-06-28 23:59:59"
        }
        this.$http.post("HomeScreen/UserHistory", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.$refs.tianmap.initTrack("姓名",response.data.DATA)
            } else {
                //
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },
    updateTrackButtons(type){
        console.log("回放状态")
        console.log(type)
    },
    //重置
    resetTrack(){
      this.$refs.tianmap.resetTrack()
    },
    //暂停
    pauseTrack(){
      this.$refs.tianmap.pauseTrack()
    },
    //继续
    keeponTrack(){
      this.$refs.tianmap.keeponTrack()
    },

    auditing(item){
        this.auditingUserInfo=item
        this.auditingModal=true
        this.auditingTitle=item.DISPLAYNAME+"审核"
        this.auditingRadio="1"
    },
    sendAuditing(){
        var params={
            userSnowID: this.auditingUserInfo.SNOWID,
            isBan: this.auditingRadio,
        }
        this.$http.post("SysUser/BanUser", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("审核已提交")
                    this.auditingModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true 
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }  
             });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>